// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types


import { Filter } from "./hooks/useDiamondsFilters";
import { Diamonds as DiamondType } from "./models/products";
import { Color } from "./models/misc";


const pipe = <R>(fn1: (a: R) => R, ...fns: Array<(a: R) => R>) =>
  fns.reduce((prevFn, nextFn) => value => nextFn(prevFn(value)), fn1);

const dataUrlToBase64 = (dataUrl: string): string => dataUrl.replace(/^data:image\/(\w*);base64,/, "");

const removeEmptyStrings = (input: unknown): unknown => {
  const inputIndexable = input as { [index: string]: any };

  const result = Object.keys(inputIndexable).reduce((obj, key) => {
    const property = inputIndexable[key];
    if (typeof property === "object") {
      obj[key] = removeEmptyStrings(property);
    }

    obj[key] = property === "" ? null : property;
    return obj;
  }, {} as { [index: string]: unknown });

  return result;
};

const convertToLocale = (price: number | undefined): string | undefined => {
  if (!price) {
    return;
  }

  return price.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

const truncateText = (text: string, windowsWidth?: number) => {
  if (text.length > 10 && windowsWidth && windowsWidth < 1600) {
    return `${text.substring(0, 9)}...`;
  }
  if (text.length > 14 && !windowsWidth) {
    return `${text.substring(0, 13)}...`;
  }

  return text;
};


type Overtones = {
  [key: string]: number;
};

const filterOvertoneOrIntensity = (data: unknown, filter: Filter<unknown>, colors: Color[]): boolean => {
  const filterValue = filter.value as number[];

  // Diamonds filtering by overtone
  if (filter.key === "colorId") {
    let overtones: Overtones = {
      BROWN: colors.find(color => color.name === "Brown")!.id,
      ORANG: colors.find(color => color.name === "Orange")!.id,
      GRAY: colors.find(color => color.name === "Gray")!.id,
      PURPL: colors.find(color => color.name === "Purple")!.id,
      YELLOW: colors.find(color => color.name === "Yellow")!.id,
      BLU: colors.find(color => color.name === "Blue")!.id,
      GREEN: colors.find(color => color.name === "Green")!.id,
      PINK: colors.find(color => color.name === "Pink")!.id,
      RED: colors.find(color => color.name === "Red")!.id,
      VIOLET: colors.find(color => color.name === "Violet")!.id,
    };

    const checkForOvertone = (filterValue: number[], diamond: DiamondType) => {
      return filterValue.some(value => {
        const findOvertoneColor = Object.keys(overtones).find(key => overtones[key] === value) as string;
        const checkWord = diamond.overtone && findOvertoneColor ? diamond.overtone.search(findOvertoneColor) : -1;
        const ifTrue = checkWord !== -1 || diamond.colorId === value ? true : false;
        return ifTrue;
      });
    };
    return checkForOvertone(filterValue, data as DiamondType);
  }
  // Diamonds filtering by fancyIntensity
  if (filter.key === "fancyIntensity") {
    let intensities: Overtones = {
      Faint: colors.find(color => color.name === "Faint")!.id,
      Very_Light: colors.find(color => color.name === "Very Light")!.id,
      Light: colors.find(color => color.name === "Light")!.id,
      Fancy_Light: colors.find(color => color.name === "Fancy Light")!.id,
      Fancy: colors.find(color => color.name === "Fancy")!.id,
      Fancy_Intense: colors.find(color => color.name === "Fancy Intense")!.id,
      Fancy_Vivid: colors.find(color => color.name === "Fancy Vivid")!.id,
      Fancy_Deep: colors.find(color => color.name === "Fancy Deep")!.id,
      Fancy_Dark: colors.find(color => color.name === "Fancy Dark")!.id,
    };
    const checkForIntensities = (filterValue: number[], diamond: DiamondType) => {
      return filterValue.some(value => {
        const findIntensity = Object.keys(intensities)
          .find(key => intensities[key] === value)
          ?.toUpperCase()
          .replace("_", " ") as string;
        const checkWord =
          diamond.fancyIntensity && diamond.fancyIntensity.toLowerCase() === findIntensity.toLowerCase() ? true : false;
        return checkWord;
      });
    };
    return checkForIntensities(filterValue, data as DiamondType);
  }
  return false;
};

export { dataUrlToBase64, pipe, removeEmptyStrings, convertToLocale, truncateText, filterOvertoneOrIntensity };
