import React, { lazy, Suspense, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import Loading from "./components/loading";
import PrivateRoute from "./components/private-route";
import PublicRoute from "./components/public-route";
import history from "./history";
import * as auth from "./store/auth";
import * as domain from "./store/domain";
import { actions as dataActions } from "./store/data";
import { actions as pushNotificationsActions } from "../src/store/pushNotifications";
import { ApplicationState } from "./store/store";
import ReactGa from "react-ga";
import IdleTimerComponent from "./components/idleTimer";

const layout = lazy(async () => import("./components/layout"));

const cart = lazy(() => import("./pages/cart/index"));
const shows = lazy(() => import("./pages/shows"));
const home = lazy(() => import("./pages/home/index"));
const welcome = lazy(() => import("./pages/welcome"));
const contact = lazy(() => import("./pages/contact"));
const aboutUs = lazy(() => import("./pages/about-us"));
const login = lazy(() => import("./pages/login/login"));
const admin = lazy(() => import("./pages/admin/admin-home"));
const account = lazy(() => import("./pages/account/account"));
const maintenance = lazy(() => import("./pages/maintenance"));
const registration = lazy(() => import("./pages/registration"));
const confirmEmail = lazy(() => import("./pages/confirm-email"));
const resetPassword = lazy(() => import("./pages/reset-password"));
const changePassword = lazy(() => import("./pages/change-password"));
const singleProduct = lazy(() => import("./pages/singleProduct"));
const oneOfAKind = lazy(() => import("./pages/one-of-a-kind"));
const classic = lazy(() => import("./pages/classic"));
const tennisNecklaces = lazy(() => import("./pages/tennis-necklaces"));
const tennisBracelets = lazy(() => import("./pages/tennis-bracelets"));
const eternityBand = lazy(() => import("./pages/eternityBand"));
const studs = lazy(() => import("./pages/studs"));
const watches = lazy(() => import("./pages/watches"));
const bridal = lazy(() => import("./pages/bridal"));
const pendants = lazy(() => import("./pages/pendants"));
const diamond = lazy(() => import("./pages/diamonds"));
const singleDiamond = lazy(() => import("./pages/singleDiamond"));
const necklaces = lazy(() => import("./pages/necklaces"))

const Router = (props: TProps) => {
  const { fetchIsMaintenance, initUserState, fetchDomainLayout, user } = props;
  // const isServiceWorkerUpdated = useSelector<ApplicationState, boolean>(state => state.pushNotifications.data);

  const router = useHistory();
  useEffect(() => {
    ReactGa.pageview(window.location.pathname + window.location.search);
    fetchIsMaintenance();
    initUserState();
  }, [fetchIsMaintenance, initUserState, router.location.pathname]);
  useEffect(() => {
    fetchDomainLayout();
  }, [fetchDomainLayout]);

  return props.retrieved ? (
    <Suspense fallback={<Loading />}>
      <IdleTimerComponent></IdleTimerComponent>
      <PublicRoute exact path="/" component={home} layout={layout} pageTitle={"Home"} />
      <PublicRoute path="/about-us" component={aboutUs} layout={layout} withLogos={true} pageTitle={"About Us"} />
      <PublicRoute path="/change-password" component={changePassword} layout={layout} pageTitle={"Change Password"} />
      <PublicRoute path="/confirm-email" component={confirmEmail} layout={layout} pageTitle={"Confirm Email"} />
      <PublicRoute path="/contact" component={contact} layout={layout} pageTitle={"Contact"} />
      <PublicRoute path="/login" component={login} layout={layout} pageTitle={"Login"} />
      <PublicRoute path="/maintenance" component={maintenance} pageTitle={"Maintenance"} />
      <PublicRoute path="/register" component={registration} layout={layout} pageTitle={"Register"} />
      <PublicRoute path="/reset-password" component={resetPassword} layout={layout} pageTitle={"Reset Password"} />
      <PublicRoute path="/shows" component={shows} layout={layout} pageTitle={"Shows"} />
      <PublicRoute path="/welcome" component={welcome} layout={layout} pageTitle={"Welcome"} />
      <PublicRoute
        path="/products/:stockNumber"
        layout={layout}
        component={singleProduct}
        pageTitle={"Single Product"}
      />      
      <PublicRoute
      path="/diamond/:stockNumber"
      layout={layout}
      component={singleDiamond}
      pageTitle={"Single Diamond"}
    />
      <PublicRoute path="/rings" component={oneOfAKind} layout={layout} pageTitle={"Rings"} />
      <PublicRoute
        path="/necklaces&pendants"
        component={oneOfAKind}
        layout={layout}
        pageTitle={"Necklaces & Pendants"}
      />
      <PublicRoute path="/bracelets" component={oneOfAKind} layout={layout} pageTitle={"Bracelets"} />
      <PublicRoute path="/earrings" component={oneOfAKind} layout={layout} pageTitle={"Earrings"} />
      <PublicRoute path="/one-of-a-kind" component={oneOfAKind} layout={layout} pageTitle={"One Of A Kind"} />
      <PublicRoute path="/classic" component={classic} layout={layout} pageTitle={"Classics"} />
      <PublicRoute path="/studs" component={studs} layout={layout} pageTitle={"Studs"} />
      <PublicRoute path="/watches" component={watches} layout={layout} pageTitle={"Watches"} />
      <PublicRoute path="/necklaces" component={necklaces} layout={layout} pageTitle={"Necklaces"} />
      <PublicRoute path="/bridal" component={bridal} layout={layout} pageTitle={"Bridal"} />
      <PublicRoute path="/diamonds" component={diamond} layout={layout} pageTitle={"Diamonds"} />
      <PublicRoute path="/pendants" component={pendants} layout={layout} pageTitle={"Pendants"} />
      <PublicRoute path="/tennis-necklaces" component={tennisNecklaces} layout={layout} pageTitle={"Tennis-Necklaces"} />
      <PublicRoute path="/tennis-bracelets" component={tennisBracelets} layout={layout} pageTitle={"Tennis-Bracelets"} />
      <PublicRoute path="/eternity-band" component={eternityBand} layout={layout} pageTitle={"Eternity-Band"} />
      <PrivateRoute path="/cart" component={cart} layout={layout} pageTitle={"Cart"} />
      <PrivateRoute path="/account" component={account} layout={layout} pageTitle={"Account"} />
      <PrivateRoute path="/admin" component={admin} roles={["Administrator"]} pageTitle={"Admin"} />
      {props.isMaintenance && !history.location.pathname.startsWith("/admin") && <Redirect to="/maintenance" />}
    </Suspense>
  ) : null;
};

const connector = connect(
  ({ auth: authDate, data }: ApplicationState) => ({
    retrieved: authDate.retrieved,
    isMaintenance: data.maintenance.isEnabled,
    user: authDate?.user,
  }),
  {
    fetchIsMaintenance: dataActions.fetchMaintenanceState,
    initUserState: auth.actions.initUserState,
    fetchDomainLayout: domain.actions.fetchDomainLayout,
    ...pushNotificationsActions,
  }
);

type TProps = ConnectedProps<typeof connector>;

export default connector(Router);
