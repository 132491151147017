import { AxiosResponse } from "axios";
import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { REFETCH_DATA_INTERVAL } from "../constants";
import { DiamondStatusEnum, Diamonds as DiamondsType, Product } from "../models/products";
import * as diamondsService from "../services/diamonds";
import { ApplicationState } from "./store";
import { actions as wishListActions } from "./wishList";

export type State = {
  all: Product<DiamondsType>[] | null;
  highlighted: Product<DiamondsType> | null;
  singleDiamond: Product<DiamondsType> | null;
};

export const fetchAllDiamonds = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { diamonds } = getState();

    if (!!diamonds.all) {
      return;
    }

    try {
      const data = await diamondsService.getAllDiamonds(true);
      dispatch(fetchAllDiamonds.success(data));

      setInterval(async () => {
        const data = await diamondsService.getAllDiamonds(true);
        dispatch(fetchAllDiamonds.success(data));
      }, REFETCH_DATA_INTERVAL);
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@DIAMONDS/FETCH_ALL", resolve => (diamonds: Product<DiamondsType>[] | null) =>
      resolve(diamonds)
    ),
  }
);

export const fetchSingleDiamond = Object.assign(
  (stockNumber: string) => async (dispatch: Dispatch) => {
    try {
      const result = await diamondsService.getDiamond(stockNumber);
      dispatch(fetchSingleDiamond.success(result));
    } catch (error) {
      console.error("Failed to fetch single diamond", error);
      // Handle error if needed
    }
  },
  {
    success: createActionCreator(
      "@@PRODUCTS/SINGLE_DIAMOND/FETCH",
      resolve => (data: Product<DiamondsType>) => resolve(data)
    ),
  }
);

export const clearSingleDiamond = createActionCreator("@@PRODUCTS/SINGLE_DIAMOND/CLEAR", resolve => () => resolve());

const fetchHighlightedDiamond = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const data = await diamondsService.getHighlightedDiamond();
      dispatch(fetchHighlightedDiamond.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@DIAMONDS/FETCH_HIGHLIGHTED", resolve => (diamond: Product<DiamondsType>) =>
      resolve(diamond)
    ),
  }
);

const changeStatus = Object.assign(
  (diamondId: number, status: DiamondStatusEnum, userId: string = "") => async (dispatch: Dispatch) => {
    try {
      const response: AxiosResponse<any> = await diamondsService.changeStatus(diamondId, status);
      if (response.status === 200) {
        dispatch(changeStatus.success({ diamondId, status, userId }));
      }
    } catch {
      return;
    }
  },
  {
    success: createActionCreator(
      "@@DIAMONDS/CHANGE_STATUS",
      resolve => (result: { diamondId: number; status: DiamondStatusEnum; userId: string }) => resolve(result)
    ),
  }
);

const resetDiamonds = Object.assign(
  () => (dispatch: Dispatch) => {
    dispatch(resetDiamonds.success());
  },
  {
    success: createActionCreator("@@DIAMONDS/RESET", resolve => () => resolve()),
  }
);

const defaultState: State = {
  all: null,
  highlighted: null,
  singleDiamond: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeStatus.success, (state, action) =>
    produce(state, draft => {
      const diamond = (draft.all || []).find(d => d.id === action.payload.diamondId);
      diamond && (diamond.product.holdUserId = action.payload.userId);
      diamond && (diamond.product.status = action.payload.status);
      draft.highlighted &&
        draft.highlighted.id === action.payload.diamondId &&
        (draft.highlighted.product.status = action.payload.status);
    })
  ),
  handleAction(resetDiamonds.success, () => ({ highlighted: null, all: null, singleDiamond: null })),
  handleAction(fetchAllDiamonds.success, (state, action) => ({ ...state, all: action.payload })),
  handleAction(fetchHighlightedDiamond.success, (state, action) => ({ ...state, highlighted: action.payload })),
  handleAction(clearSingleDiamond, (state) => ({ ...state, singleDiamond: null })),
  handleAction(fetchSingleDiamond.success, (state, action) => ({ ...state, singleDiamond: action.payload })),
  handleAction(wishListActions.removeFromWishList.success, (state, action) =>
    produce(state, draft => {
      if (Array.isArray(action.payload)) {
        const diamondIds = action.payload as number[];
        draft.all = draft.all!.map(d => {
          if (diamondIds.some(id => id === d.id)) {
            d.product.status = DiamondStatusEnum.Suspended;
            return d;
          }
          return d;
        });
      }
    })
  ),
]);

const actions = {
  changeStatus,
  fetchAllDiamonds,
  fetchHighlightedDiamond,
  resetDiamonds,
  fetchSingleDiamond,
  clearSingleDiamond
};

export { actions, reducer };
